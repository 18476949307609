<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-lg-0 pt-2 pb-5 form"
      >
        <b-col sm="8" md="6" lg="8" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1" />
          <b-card-text class="mb-2 register"> Register to VEP </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{}">
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="handleRegister"
            >
              <!-- First Name -->
              <!-- <b-form-group label="First Name" label-for="register-first_name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required|first_name"
                >
                  <b-form-input
                    id="register-first_name"
                    v-model="form.first_name"
                    name="register-first_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jhon"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <!-- Last Name -->
              <!-- <b-form-group label="Last Name" label-for="register-last_name">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required|last_name"
                >
                  <b-form-input
                    id="register-last_name"
                    v-model="form.last_name"
                    name="register-last_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jhon"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="form.email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="form.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--confirm password -->
              <b-form-group
                label-for="register-password"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="form.confirmPassword"
                      class="form-control-merge"
                      :type="confirmPasswordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="confirmPasswordToggleIcon"
                        class="cursor-pointer"
                        @click="toggleConfirmPasswordVisibility()"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  name="checkbox-1"
                  v-model="acceptTerms"
                  @change="handleAcceptTerms()"
                >
                  Accept terms and conditions
                </b-form-checkbox>
              </b-form-group> -->

              <b-button variant="primary" block type="submit">
                Get Started
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already on our Plateform?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Sign in</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>
          <b-button
            variant="darken"
            class="button"
            block
            type="submit"
            @click.prevent="validationForm"
          >
            <feather-icon icon="LinkedinIcon" />

            Continue with Linkedin
          </b-button>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import useJwt from "@/auth/jwt/useJwt"
// import { getHomeRouteForLoggedInUser } from "@/auth/utils"

import store from "@/store/index"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { email, required } from "@validations"
import axios from "@/libs/axios"

import {
  // eslint-disable-next-line indent
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  // BFormInvalidFeedback,
  BRow,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { getHomeRouteForLoggedInUser } from "@/auth/utils"
import useJwt from "@/auth/jwt/useJwt"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // BFormInvalidFeedback,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmPassword: "",
        type: "candidate",
      },
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
      invalid: true,
      acceptTerms: false,
      confirmPassErr: "",
      confirmPasswordFieldType: "password",
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon"
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    toggleConfirmPasswordVisibility() {
      if (this.confirmPasswordFieldType === "password") {
        return (this.confirmPasswordFieldType = "text")
      }
      if (this.confirmPasswordFieldType === "text") {
        return (this.confirmPasswordFieldType = "password")
      }
    },

    validationForm() {
      // this.$refs.registerForm.validate().then((success) => {
      //   if (success) {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: "Form Submitted",
      //         icon: "EditIcon",
      //         variant: "success",
      //       },
      //     })
      //   }
      // })
    },
    // eslint-disable-next-line consistent-return
    async handleRegister() {
      // eslint-disable-next-line consistent-return
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          if (this.form.password !== this.form.confirmPassword) {
            return this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Please confirm your Password",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: "Password and Confirm password do not match",
              },
            })
          }
          console.log(this.form)
          axios
            .post("/register", this.form)
            .then((res) => {
              console.log(res)
              if (res.data.error) {
                // let errorText = ""
                // let errorCount = 0
                // // eslint-disable-next-line no-restricted-syntax, no-unused-vars
                // for (const [key, value] of Object.entries(res.data.messages.email)) {
                //   if (errorCount === 0) {
                //     errorText += value[0]
                //   } else {
                //     errorText += ` & ${value[0]} `
                //   }
                //   // eslint-disable-next-line no-plusplus
                //   errorCount++
                // }
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Something went Wrong!",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: res.data.messages.email,
                  },
                })
              } else {
                axios
                  .post("/login", this.form)
                  .then((resp) => {
                    useJwt.setToken(resp.data?.accessToken)
                    useJwt.setUserData(resp.data?.user?.userData)
                    if (resp.data?.user?.userData.role.code === "candidate") {
                      this.$router
                        .replace(
                          getHomeRouteForLoggedInUser(
                            resp.data?.user?.userData.role.code
                          )
                        )
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                              title: `Welcome ${resp.data?.user?.userData?.name}`,
                              icon: "CoffeeIcon",
                              variant: "success",
                              text: `You have successfully logged in as ${resp.data?.user?.userData.role.name}. Now you can start to explore!`,
                            },
                          })
                        })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: `Sorry ${res.data?.user?.userData?.name}`,
                          icon: "CoffeeIcon",
                          variant: "danger",
                          text: `You Don't have Permission!`,
                        },
                      })
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                    errorResponse(error, this.$router)
                  })
              }
            })
            .catch((error) => {
              console.log(error.data)
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Something went Wrong",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: "This Email is Already Taken",
                },
              })
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}
.brand_logo {
  padding-bottom: -100px !important;
  height: 50px;
}
@media only screen and (max-width: 950px) {
  .form {
    margin-top: -150px;
  }
  .register {
    margin-top: -150px;
  }
  .logo_img {
    width: 120px !important;
    margin-left: -80px;
  }
}
</style>
